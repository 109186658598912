import React, {useCallback, useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import BenefitsExpectations from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import JoinUs from '@solid-ui-blocks/Hero/Block03'
import JoinUsHeadline from '@solid-ui-blocks/Features/Block04'
import InnerHTML from 'dangerously-set-html-content'
import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import OurValues from "@solid-ui-blocks/Features/Block04/Block04";
import styles from './_styles'

const PERSONIO_HTML = `
<iframe 
  id='personio-iframe' 
  style='border: none'
  src='https://miragon.jobs.personio.de/' 
  width='100%'></iframe>
<script>
  // Automatically adjust height of iframe
  window.addEventListener('message', function(e) {
    var iframe = document.querySelector('#personio-iframe');

    if (iframe) {
        var eventName = e.data[0];
        var data = e.data[1];
        switch(eventName) {
            case 'setHeight':
                iframe.style.height = (data + 24) + 'px';
                break;
        }
    }
  }, false);
</script>`

const CMP_VENDOR_PERSONIO = 'c34241'

const Career = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    const [personioConsent, setPersonioConsent] = useState('unknown')

    const checkConsent = useCallback(() => {
        /* eslint-disable no-undef */
        // @ts-ignore
        if (typeof __cmp === 'undefined') {
            setTimeout(checkConsent, 50)
            return
        }

        const data = __cmp('getCMPData')
        if (!data?.consentExists) {
            setTimeout(checkConsent, 500)
            return
        }

        // User has consented to vendor "personio"
        if (data?.vendorConsents && data.vendorConsents[CMP_VENDOR_PERSONIO] === true) {
            setPersonioConsent('yes')
        } else {
            setPersonioConsent('no')
        }
        /* eslint-enable no-undef */
    }, [])

    const grantConsent = useCallback(() => {
        /* eslint-disable no-undef */
        // @ts-ignore
        if (typeof __cmp === 'undefined') {
            setTimeout(grantConsent, 50)
            return
        }

        __cmp('setVendorConsent', [CMP_VENDOR_PERSONIO, 1])
        setTimeout(checkConsent, 100)
        /* eslint-enable no-undef */
    }, [checkConsent])

    useEffect(() => {
        checkConsent()
    }, [checkConsent])

    return (
        <Layout {...props}>
            <Seo title='Karriere'/>
            {/* Modals */}
            <ModalWithTabs content={content['authentication']} reverse/>
            <ModalWithTabs content={content['contact']}/>
            <ModalSimple content={content['advertisement']}/>
            {/* Blocks */}
            <Header content={content['header-light']}/>
            <Container variant='full' sx={styles.heroContainer}>
                <OurValues content={content['our-values']}></OurValues>
                <Divider space='5'/>
            </Container>
            <Divider space='4'/>
            <JoinUs content={content['joinus-first']}></JoinUs>
            <Divider space='5'/>
            <BenefitsExpectations content={content['benefits']}/>
            <Divider space='5'/>
            <BenefitsExpectations content={content['expectations']} reverse/>
            <Divider space='5'/>
            <JoinUs content={content['joinus']}></JoinUs>
            <Divider space='5'/>
            <JoinUsHeadline content={content['joinusheadline']}/>
            <Container>
                <div id='join-widget' style={{
                    backgroundColor: 'white',
                    border: '2px solid #CCC',
                    borderRadius: '4px',
                    paddingTop: '48px',
                    marginTop: '-24px'
                }}>
                    {personioConsent === 'yes' && (
                        <InnerHTML html={PERSONIO_HTML}/>
                    )}
                    {personioConsent === 'no' && (
                        <div style={{
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#666',
                            backgroundColor: 'white',
                            marginBottom: '24px'
                        }}>
                            Wir benötigen Deine Zustimmung, um Dir unsere offenen Stellen zu zeigen.
                            <a
                                href="#join-widget"
                                style={{color: 'link'}}
                                onClick={grantConsent}>Klicke hier, um uns Deine Zustimmung zu erteilen.</a>
                        </div>
                    )}
                    {personioConsent === 'unknown' && (
                        <div style={{
                            height: '50px',
                            textAlign: 'center',
                            padding: '16px',
                            color: '#666',
                            backgroundColor: 'white'
                        }}>
                            Laden...
                        </div>
                    )}
                </div>
            </Container>
            <Divider space='2'/>
            <JoinUsHeadline content={content['joinusfooter']}/>
            <Divider space='5'/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
    query innerpageSiteCareerBlockContent {
        allBlockContent(
            filter: { page: { in: ["site/career", "shared"] } }
        ) {
            nodes {
                ...BlockContent
            }
        }
    }
`

export default Career
